import { ref } from 'vue'
import { getIdToken } from 'firebase/auth'
import { getUser } from './getAuth'

const useFetch = () => {
  const { user } = getUser()
  const error = ref(null)
  const isLoading = ref(false)

  const fetchData = async (url = '', method = 'GET', body) => {
    isLoading.value = true
    error.value = null

    try {
      const res = await fetch(`${process.env.VUE_APP_SERVER}${url}`, {
        method: method.toUpperCase(),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getIdToken(user.value)}`
        },
        body: body ? JSON.stringify(body) : null
      })

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? await res.json() : url.includes('download') ? await res.blob() : null;

      if (res.status !== 200) {
        throw (data?.error || { message: res.statusText })
      }

      error.value = null
      isLoading.value = false
      return data
    } catch(err) {
      error.value = err.message
      isLoading.value = false
    }
  }

  return { error, isLoading, fetchData }
}

export default useFetch