import { ref, watchEffect } from 'vue'
import { collection, doc, query, where, orderBy, limit, onSnapshot, getDocs } from 'firebase/firestore'
import { projectFirestore } from '../firebase/config'

const useFirestore = () => {
	const isLoading = ref(false)
	const error = ref(null)
	const document = ref(null)
	const documents = ref(null)
	const isEmpty = ref(false)
	let unsubDocs = null, limitBy = 0

	const listenDocument = (collectionId, docId) => {
		isLoading.value = true
		const docRef = doc(projectFirestore, collectionId, docId)
	
		const unsubDoc = onSnapshot(docRef, (doc) => {
			if (doc.data()) {
				document.value = { ...doc.data(), id: doc.id }
				error.value = null
			} else {
				document.value = null
				error.value = 'Document doesn\'t exist'
			}

			isLoading.value = false
		}, (err) => {
			document.value = null
			error.value = err.message
			isLoading.value = false
		})
	
		watchEffect((onInvalidate) => onInvalidate(() => unsubDoc()))
	}

	const listenDocuments = (collectionId, docId, subCollectionId, attach, order, _query, _query2, _query3, _query4, _query5, noLimit) => {
		isLoading.value = true

		if (unsubDocs) unsubDocs()

		if (!attach) limitBy = 0

		if (!noLimit) limitBy += 20
		
		let queryRef = (docId && subCollectionId) 
			? query(collection(doc(projectFirestore, collectionId, docId), subCollectionId)) 
			: noLimit 
			? query(collection(projectFirestore, collectionId))
			: query(collection(projectFirestore, collectionId), limit(limitBy))

		if (_query) queryRef = query(queryRef, where(..._query))

		if (_query2) queryRef = query(queryRef, where(..._query2))
		
		if (_query3) queryRef = query(queryRef, where(..._query3))

		if (_query4) queryRef = query(queryRef, where(..._query4))

		if (_query5) queryRef = query(queryRef, where(..._query5))

		if (order) queryRef = query(queryRef, orderBy(...order))
		
		unsubDocs = onSnapshot(queryRef, (snap) => {
			let results = []
	
			snap.docs.forEach(el => {
				el.data().createdAt && results.push({ ...el.data(), id: el.id })
			})
			
			documents.value = results
			isEmpty.value = (snap.empty || snap.size < limitBy)
			error.value = null
			isLoading.value = false
		}, (err) => {
			documents.value = null
			error.value = err.message
			isLoading.value = false
		})
	
		watchEffect((onInvalidate) => onInvalidate(() => unsubDocs()))
	}

	const getDocuments = async (collectionId, _query, _query2) => {
		isLoading.value = true

		let queryRef = query(collection(projectFirestore, collectionId))

		if (_query) queryRef = query(queryRef, where(..._query))

		if (_query2) queryRef = query(queryRef, where(..._query2))

		try {
			const docs = await getDocs(queryRef)

			error.value = null
			isLoading.value = false
			return (!docs.empty) ? docs.docs.map(el => ({ ...el.data(), id: el.id })) : []
		} catch(err) {
			error.value = err.message
			isLoading.value = false
			return []
		}
	}

	return { isLoading, error, document, documents, isEmpty, listenDocument, 
		listenDocuments, getDocuments }
}

export default useFirestore